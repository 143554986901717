.login{
    background-image: url("../Img/background_login.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
    height: 100vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.login .form-group .label-title{
    text-align: left;
    font-weight: 600;
}

.login .card {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    border: unset;
}

.login .card-body{
    padding:4em 2em 2em 2em;
}

.login .contenedor{
    display: block;
    width: 80%;
    max-width: 430px;
    margin: auto;
    
}

.login .logo{
    border-radius: 10px;
    width: 70%;
    height: 100px;
    background-color: #283B4F;
    /* border: 1px solid #222; */
    position: absolute;
    margin-top: -60px;
    left: 15%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
}

.login .btn-show-password{
        //position: absolute;
        background-color: #fff;
        //padding-top:7px;
        border: 1px solid #ced4da !important;
        border-left: none !important;
        &:hover{
            cursor: pointer;
            color:#000;
            background-color: none;
        }
        &.active,&:active, &:focus {
            box-shadow: unset;
            color:#000;
            outline: 0 !important;    
        }
        
        outline: unset !important;
    }
.login input{
    &.active,&:active, &:focus {
        box-shadow: unset;
        outline: unset !important;
        border: 1px solid #ced4da !important;
    }
}

.div-submit{
    padding-top:2em;
}

.tac{
    text-align: center;
}

.tal{
    text-align: left;
}

.tar{
    text-align: right;
}