body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #283b4f !important;
}

textarea {
  resize: none !important;
}

.btn-container {
  button,
  a,
  .btn {
    margin: 0 5px;
  }
}

.card {
  label.title {
    font-weight: 600;
  }
}

.details {
  label.title {
    font-size: 12px;
  }
  p {
    font-size: 16px;
  }
}

.mg-top {
  margin-top: 1.5em;
}

.btn {
  padding-top: 0.2em !important;
  padding-bottom: 0.2em !important;
}

.form-group label {
  font-weight: 600;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}

.card {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: unset;
}

.fas.fa-arrow-left {
  cursor: pointer;
}

.canchas {
  .btn-primary {
    background-color: #283b4f;
    border-color: #283b4f;
    &:focus,
    &:hover {
      background-color: #142a41;
      border-color: #142a41;
    }
  }
}

.rbc-btn-group {
  .rbc-active {
    background-color: #142a41 !important;
    border-color: #142a41 !important;
  }
  button {
    color: #fff !important;
    background-color: #283b4f !important;
    border-color: #283b4f !important;
  }
}

.agendados-list {
  padding-top: 0.5em;

  padding-bottom: 0.5em;

  .mg-0 {
    margin: 0;
  }
  .pd-0 {
    padding: 0 0.5em;
  }
  .mg-top {
    margin-top: 1em;
  }
  .title {
    font-size: 16px;
    display: block;
  }
  .disabled {
    background-color: #efefef;
  }
  .cards-container {
    max-height: 700px;
    overflow-y: hidden;
    padding: 0 0.1em 1em 0.1em;
    &:hover {
      overflow-y: scroll;
    }
  }
  label {
    font-weight: 400;
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
}
