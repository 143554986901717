.no-disabled-style {
  background-color: #fff !important;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-template-rows: 2.5rem 2.5rem;
  gap: 0.25rem 0.25rem;
}

.grid button {
  border-radius: .1rem;
  font-size: 0.85em;
}
