


.pagination{

    .page-item{
        border:none;
        
        .page-link{
            border-radius: 50%;
           
            border:none;
        }
    
    }

    .page-item.active{

        .page-link{
            background-color: #1F54BB !important;
            border:none;
        }
    
    }

    .limit{
        
    }
}